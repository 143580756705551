.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95); /* Increased opacity for darker overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-container {
  position: relative;
  background-color: #fff;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden; /* Hide the scrollbar */
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Increased box shadow */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.lightbox-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}

.lightbox-caption {
  margin-top: 10px;
  font-size: 18px; /* Larger font size for caption */
  color: #fff; /* Light text color */
}
.lightbox-thumbnail {
    transition: transform 0.3s ease-in-out;
}

.lightbox-thumbnail:hover {
    transform: scale(1.1);
    z-index: 1; /* Ensure the hovered image is on top */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.image-info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
}

.lightbox-thumbnail:hover .image-info {
    opacity: 1; /* Show info on hover */
}

.lightbox-image-info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
}

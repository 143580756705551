.section-title.text-center {
    /* margin: 0 auto 50px; */
}
.section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #012c6d;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-title .subtitle::after, .section-title .subtitle::before {
    font-family: DINPro;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 14px;
    margin-right: 10px;
}

section-title .subtitle::after {
    margin-right: 0;
    margin-left: 10px;
}
.section-title .subtitle {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    color: #012c6d;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-title .title {
    position: relative;
    margin: 0;
    font-size: 35px !important;
    line-height: 1.2;
    margin-bottom: 15px;
}
.sigma_video-popup-wrap {
    position: relative;
    transition: 0.3s;
}
.sigma_broadcast-video .sigma_video-popup-wrap img {
    width: 100%;
}
.sigma_video-popup-wrap .sigma_video-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.sigma_video-popup {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: #012c6d;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.sigma_video-popup:hover {
    background-color: tomato; /* Change to the desired hover color */
}

.sigma_video-popup::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 2px solid #fff;
    animation-name: pulseInOut;
    opacity: 0;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.fa-play:before {
    content: "\f04b";
}
.sigma_video-popup::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 2px solid #fff;
    animation-name: pulseInOutSM;
    opacity: 0;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
}
.sigma_box {
    padding: 30px;
    margin-left: 50px !important;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.custom-primary {
    color: #012c6d !important;
}
.fw-600 {
    font-weight: 600;
}
.fs-16 {
    font-size: 16px;
}
.sigma_btn-custom {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    background-color: #012c6d;
    border: 0;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0px;
    font-weight: 600;
    overflow: hidden;
    z-index: 1;
}
.section-button {
    margin-top: 30px;
}
.sigma_btn-custom::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    background-color: #d43308;
    width: 10px;
    height: calc(100% - 15px);
    z-index: -1;
    transition: .3s;
    opacity: 0;
    visibility: hidden;
}
.sigma_video-popup-wrap {
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.sigma_broadcast-video .sigma_video-popup-wrap img {
    width: 100%;
}
.sigma_video-popup.popup-sm {
    width: 50px;
    height: 50px;
}
.sigma_video-popup-wrap .sigma_video-popup {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.sigma_video-popup::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 2px solid #fff;
    animation-name: pulseInOut;
    opacity: 0;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.sigma_video-popup::after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 2px solid #fff;
    animation-name: pulseInOutSM;
    opacity: 0;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
}

@keyframes pulseInOut {
    0% {
        opacity: 1;
        transform: scale(.3);
    }
    100% {
        opacity: 0;
        transform: scale(1.3);
    }
}

@keyframes pulseInOutSM {
    0% {
        opacity: 1;
        transform: scale(.3);
    }
    100% {
        opacity: 0;
        transform: scale(1.7);
    }
}

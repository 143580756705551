 .u-float-right {
     float: right;
 }

 .u-flex-center {
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
     justify-content: center;
     -webkit-align-items: center;
     align-items: center;
 }

 .subtle {
     color: #000000;
     font-size: 13px;
 }

 .card-media {
     border-radius: 2px;
     box-shadow: 0 2px 8px rgba(0, 0, 0, .12);
     height: 155px;
     transition: all 300ms ease-out;
     width: 100%;
     margin-top: 10px;
 }
 .card-media-object-container {
     background: none;
     float: left;
     height: 100%;
     width: 35%;
     position: relative;
 }

 .card-media-object {
     background-position: center center;
     background-size: cover;
     height: 100%;
 }

 .card-media-object:after {
     content: " ";
     display: block;
     height: 100%;
     left: 0;
     opacity: 0;
     position: absolute;
     right: 0;
     top: 0;
     transition: all 300ms ease-out;
     z-index: 10;
 }

 .card-media-object-tag {
     background-color: #fff;
     border-radius: 2px;
     padding: 2px 7px;
     position: absolute;
     right: 10px;
     top: 10px;
 }

 .card-media-object-social-list {
     bottom: 4px;
     left: 10px;
     list-style-type: none;
     margin: 0;
     padding: 0;
     position: absolute;
     z-index: 20;
 }

 .card-media-object-social-list li {
     border-radius: 50%;
     display: inline-block;
     height: 30px;
     margin-right: 6px;
     opacity: 0;
     overflow: hidden;
     transform: translateY(5px);
     transition: all 300ms ease-out;
     width: 30px;
 }

 .card-media:hover .card-media-object-social-list li {
     opacity: 1;
     transform: translateY(0);
 }

 .card-media-object-social-list li:nth-child(1) {
     transition-delay: 0;
 }

 .card-media-object-social-list li:nth-child(2) {
     transition-delay: 75ms;
 }

 .card-media-object-social-list li:nth-child(3) {
     transition-delay: 150ms;
 }

 .card-media-object-social-list-item-additional {
     border: 1px solid #fff;
     color: #fff;
     font-size: 12px;
     padding-top: 7px;
     text-align: center;
 }

 .card-media-body {
     background-color: #fff;
     float: left;
     height: 100%;
     padding: 12px 15px;
     position: relative;
     width: 65%;
 }

 .card-media-body-top {
     display: block;
 }

 .card-media-body-top-icons {
     margin-top: -2px;
     opacity: 0;
     transition: all 300ms ease-out;
     transform: translateY(-5px);
 }

 .card-media:hover .card-media-body-top-icons {
     opacity: 1;
     transform: translateY(0);
 }

 .card-media-body-top-icons>svg {
     cursor: pointer;
     margin-left: 10px;
     transition: all 300ms ease-out;
 }

 .card-media-body-top-icons>svg:hover {
     fill: #444;
 }

 .card-media-body-heading {
     display: block;
     margin-top: 10px;
     font-weight: 500;
     font-size: 20px;
     color: #000;
     padding-bottom: 5px;

 }

 .card-media-body-supporting-bottom {
     position: absolute;
     bottom: 10px;
     left: 0;
     opacity: 1;
     padding: 0 15px;
     transition: all 300ms ease-out;
     width: 100%;
 }

 .card-media:hover .card-media-body-supporting-bottom {
     opacity: 0;
     transform: translateY(-8px);
 }

 .card-media-body-supporting-bottom-text {
     display: inline-block;
 }

 .card-media-body-supporting-bottom-reveal {
     opacity: 0;
     transform: translateY(8px);
     transition: all 300ms ease-out;
 }

 .card-media:hover .card-media-body-supporting-bottom-reveal {
     opacity: 1;
     transform: translateY(0);
 }

 .card-media-link {
     color: #41C1F2;
     text-decoration: none;
 }

 .view-more-btn{
    background-color: #0a68ca;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: none;
    margin: 10px 0;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
 }